<template>
  <div class="content">
    <LandingNannyContractorMobileSectionHero />
    <LandingNannyContractorSectionAdvantages />
    <LandingNannyContractorMobileSectionGratis />
    <LandingNannyContractorSectionList />

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useIsMobile from "@/composables/useIsMobile";

import NavMobile from "@/components/NavMobile.vue";

import LandingNannyContractorMobileSectionHero from "@/components/Landing/Nanny/Contractor/Mobile/LandingNannyContractorMobileSectionHero.vue";
import LandingNannyContractorSectionAdvantages from "@/components/Landing/Nanny/Contractor/LandingNannyContractorSectionAdvantages.vue";
import LandingNannyContractorMobileSectionGratis from "@/components/Landing/Nanny/Contractor/Mobile/LandingNannyContractorMobileSectionGratis.vue";
import LandingNannyContractorSectionList from "@/components/Landing/Nanny/Contractor/LandingNannyContractorSectionList.vue";

export default {
  components: {
    LandingNannyContractorMobileSectionHero,
    LandingNannyContractorSectionAdvantages,
    LandingNannyContractorMobileSectionGratis,
    LandingNannyContractorSectionList,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();

    return {
      isMobile,
    };
  },
};
</script>

<style scoped>
.content {
  padding-bottom: var(--nav-mobile-height);
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
