<template>
  <section class="section-gratis">
    <div class="bg">
      <SvgIllustrationsLandingNannyAdvantageBgDesktop />
    </div>

    <div class="content">
      <div class="col-left">
        <p class="zero">0<span class="percentage">%</span></p>
      </div>
      <div class="col-right">
        <div class="wrapper">
          <BaseText :text="'# gratis'" class="tag" />
          <BaseH2 :text="'Zero kosztów'" />
          <div class="text">
            <BaseText
              :text="'Nie pobieramy opłat ani odsetek za korzystanie z naszego serwisu.'"
            />
            <BaseText
              :text="'Pieniądze są wypłacane Ci bezpośrednio przez rodziców '"
            />
          </div>
        </div>
      </div>
    </div>

    <ButtonCta
      :to="{ name: ROUTE_REGISTRATION_NAME }"
      :label="'Zarejestruj się'"
      :size="'large'"
    />
  </section>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import SvgIllustrationsLandingNannyAdvantageBgDesktop from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyAdvantageBgDesktop.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import { ROUTE_REGISTRATION_NAME } from "@/router/constants";

export default {
  components: {
    BaseH2,
    BaseText,
    SvgIllustrationsLandingNannyAdvantageBgDesktop,
    ButtonCta,
  },

  setup() {
    return { ROUTE_REGISTRATION_NAME };
  },
};
</script>

<style scoped>
.section-gratis {
  width: 100%;
  height: 700px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}
.section-gratis .bg {
  position: absolute;
  width: 100vw;
  height: 700px;
  z-index: -1;
  overflow: hidden;
}
.section-gratis .content {
  width: 100%;
  display: flex;
  column-gap: 40px;
  justify-content: center;
}
.col-left,
.col-right {
  padding-top: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-right {
  padding-top: 45px;
}
.col-left .zero {
  font-weight: 500;
  font-size: 288px;
  color: #fe881c;
}
.col-left .zero .percentage {
  font-size: 190px;
}
.col-right .wrapper {
  width: 450px;
  display: flex;
  flex-flow: column;
  row-gap: 25px;
}
.col-right .tag {
  font-weight: 500;
  color: #fe881c;
}
.col-right .text {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}
</style>
