<template>
  <section class="section-hero">
    <div class="bg"><SvgIllustrationsLandingNannyHeroBgMobile /></div>

    <HeaderMobileLanding />

    <section class="section-title">
      <BaseH1
        :text="'Znajdź pracę nianą i babysitterąwe Wroclawiu!'"
        class="title"
      />
      <TextDescription
        :text="'Tablica do samodzielnego poszukiwania niań i babysitterów'"
      />
    </section>

    <div class="illustration">
      <SvgIllustrationsLandingNannyMain />
    </div>
  </section>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsLandingNannyHeroBgMobile from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyHeroBgMobile.vue";
import SvgIllustrationsLandingNannyMain from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyMain.vue";
import HeaderMobileLanding from "@/components/Header/HeaderMobileLanding.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    SvgIllustrationsLandingNannyHeroBgMobile,
    SvgIllustrationsLandingNannyMain,
    HeaderMobileLanding,
  },
};
</script>

<style scoped>
.section-hero {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.section-hero .bg {
  position: absolute;
  width: 100vw;
  height: 57vh;
  z-index: -1;
}
.section-hero .bg svg {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .logo {
  width: 86px;
}
.section-title {
  width: 100%;
  margin: 23px 0px 15px;
  display: flex;
  flex-flow: column;
  row-gap: 35px;
  align-items: center;
}
.section-hero .illustration {
  width: 100%;
  height: 333px;
  display: flex;
  justify-content: center;
}
.section-hero .illustration svg {
  height: 100%;
  width: auto;
}
</style>
