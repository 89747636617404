<template>
  <section class="landing-section-list">
    <ListAnonymus
      v-model:filter="currentListFilter.filter"
      v-model:itemsClass="currentListFilter.class"
      v-model:itemsType="currentListFilter.type"
      :items="currentListItems.items"
      :itemsTotalAmount="currentListItems.totalCount"
      :isFetching="false"
      @fetchMore="fetchMore"
    />
  </section>
</template>

<script>
import useList from "@/composables/useList.js";
import ListAnonymus from "@/components/List/ListAnonymus.vue";

export default {
  components: {
    ListAnonymus,
  },

  setup() {
    const { currentListFilter, currentListItems, fetchMore } = useList();

    return {
      currentListFilter,
      currentListItems,
      fetchMore,
    };
  },
};
</script>

<style scoped>
.landing-section-list {
  width: 100%;
}
@media (max-width: 1200px) {
  .landing-section-list {
    margin-bottom: 50px;
    width: 100%;
  }
}
</style>
