<template>
  <div class="content">
    <HeaderDesktopLandingFixed ref="stickyHeaderRef" />

    <LandingNannyContractorDesktopSectionHero />
    <LandingNannyContractorSectionAdvantages />
    <LandingNannyContractorDesktopSectionGratis />
    <LandingNannyContractorSectionList
      v-intersection="intersectionOptions"
      @intersection="handleIntersection"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import intersectionDirective from "@/directives/intersection";

import LandingNannyContractorDesktopSectionHero from "@/components/Landing/Nanny/Contractor/Desktop/LandingNannyContractorDesktopSectionHero.vue";
import LandingNannyContractorSectionAdvantages from "@/components/Landing/Nanny/Contractor/LandingNannyContractorSectionAdvantages.vue";
import LandingNannyContractorDesktopSectionGratis from "@/components/Landing/Nanny/Contractor/Desktop/LandingNannyContractorDesktopSectionGratis.vue";
import LandingNannyContractorSectionList from "@/components/Landing/Nanny/Contractor/LandingNannyContractorSectionList.vue";
import HeaderDesktopLandingFixed from "@/components/Header/HeaderDesktopLandingFixed.vue";

export default {
  directives: {
    intersection: intersectionDirective,
  },

  components: {
    LandingNannyContractorDesktopSectionHero,
    LandingNannyContractorSectionAdvantages,
    LandingNannyContractorDesktopSectionGratis,
    LandingNannyContractorSectionList,
    HeaderDesktopLandingFixed,
  },

  setup() {
    const stickyHeaderRef = ref(null);

    const handleIntersection = (event) => {
      if (stickyHeaderRef.value === null) {
        return;
      }

      if (event.detail.isOnScreen) {
        stickyHeaderRef.value.fix();
      } else {
        stickyHeaderRef.value.unfix();
      }
    };

    const intersectionOptions = {
      threshold: 0,
      root: null,
      rootMargin: `0px 0px -100% 0px`,
    };

    return {
      stickyHeaderRef,
      intersectionOptions,
      handleIntersection,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 100px;
}
</style>
