<template>
  <component :is="LandingView" />
</template>

<script>
import { computed } from "@vue/reactivity";

import useIsMobile from "@/composables/useIsMobile";

import LandingNannyContractorDesktop from "@/views/Landing/Nanny/Contractor/LandingNannyContractorDesktop.vue";
import LandingNannyContractorMobile from "@/views/Landing/Nanny/Contractor/LandingNannyContractorMobile.vue";
import { onMounted } from "vue";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const { isMobile } = useIsMobile();
    const LandingView = computed(() => {
      return isMobile.value
        ? LandingNannyContractorMobile
        : LandingNannyContractorDesktop;
    });

    const userStore = useUserStore();
    onMounted(() => {
      userStore.userType = "contractor";
    });
    return { LandingView };
  },
};
</script>
